#case-studies {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/green-mint-bg.jpg) fixed center center;
    background-size: cover;
    padding: 80px 0;
  }
  
  #case-studies .cta-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  #case-studies .cta-text {
    color: #fff;
  }
  
  @media (min-width: 769px) {
    #case-studies .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  
  #case-studies .cta-btn {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  #case-studies .cta-btn:hover {
    background: #2dc997;
    border: 2px solid #2dc997;
  }