body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.page-404 .title {
    font-size: 36px;
    margin-bottom: 20px;
}

.not-found-message {
    font-size: 18px;
    color: #888;
}

.back-to-home {
    margin-top: 20px;
    font-size: 18px;
    text-decoration: none;
    color: black;
}

.back-to-home:hover {
    text-decoration: underline;
}

#case-studies {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/green-mint-bg.d7f27097.jpg) fixed center center;
    background-size: cover;
    padding: 80px 0;
  }
  
  #case-studies .cta-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  #case-studies .cta-text {
    color: #fff;
  }
  
  @media (min-width: 769px) {
    #case-studies .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  
  #case-studies .cta-btn {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  #case-studies .cta-btn:hover {
    background: #2dc997;
    border: 2px solid #2dc997;
  }
